import SEO from "../../components/seo"

const DownloadPage = () => {
  if (typeof navigator !== "undefined" && typeof window !== "undefined") {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.thothtw.thoth"
    } else if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      window.location.href = "https://apps.apple.com/tw/app/id1510380151"
    } else {
      window.location.href = "https://thoth.tw"
    }
  }
  return (
    <SEO
      title="應用程式下載"
      image="https://cms.thoth.tw/uploads/mobile_banner_9ef38b0273.jpg"
    />
  )
}

export default DownloadPage
